import {ExchangeAsset, ExchangeNetwork, Network, SpotMarket, TokenContract} from "../../../../../client/types";
import {ArbitrageItem, Chain, Market} from "./types";

const convertSpotMarkets = (spotMarkets: SpotMarket[]): Market[] => (
    spotMarkets.map(market => ({
        key: `${market.id}`,
        symbol: `${market.symbol}`,
        buyUrl: market.url,
        sellUrl: market.url
    }))
)

const convertExchangeNetworks = (exchangeNetworks: ExchangeNetwork[]): Chain[] => (
    exchangeNetworks.map(network => {

        let confirm;

        if (!Number.isFinite(network.minConfirm) || !Number.isFinite(network.unLockConfirm)) {
            confirm = "\u2013";
        } else if (network.minConfirm === network.unLockConfirm) {
            confirm = network.minConfirm !== 0 ? `${network.minConfirm}` : "No";
        } else {
            confirm = `${network.minConfirm} / ${network.unLockConfirm}`;
        }

        let feeArray = [];

        if (network.fixedWithdrawFeeInUsd && network.fixedWithdrawFeeInUsd >= 1) {
            feeArray.push(`${Math.round(network.fixedWithdrawFeeInUsd)} $`);
        } else if (network.fixedWithdrawFeeInUsd) {
            feeArray.push("< 1 $");
        }

        if (network.ratioWithdrawFee) {
            feeArray.push(`${network.ratioWithdrawFee} %`);
        } else if (Number.isFinite(network.fixedWithdrawFeeInUsd) && network.fixedWithdrawFeeInUsd === 0) {
            feeArray.push("No");
        }

        const withdrawFee = feeArray.length !== 0 ? feeArray.join(" + ") : "\u2013";

        let status;

        if (network.depositEnabled && network.withdrawEnabled) {
            status = "Normal";
        } else if (!network.depositEnabled && network.withdrawEnabled) {
            status = "Withdraw";
        } else if (network.depositEnabled && !network.withdrawEnabled) {
            status = "Deposit";
        } else {
            status = "Disable";
        }

        return {
            id: network.networkId,
            symbol: network.symbol,
            depositEnabled: network.depositEnabled,
            withdrawEnabled: network.withdrawEnabled,
            fixedWithdrawFee: network.fixedWithdrawFeeInUsd,
            ratioWithdrawFee: network.ratioWithdrawFee,
            confirm: confirm,
            withdrawFee: withdrawFee,
            status: status
        }
    })
)

const convertNetwork = (network: Network): Chain => {

    let feeArray = [];

    if (network.tokenTransferFeeInUsd && network.tokenTransferFeeInUsd >= 1) {
        feeArray.push(`${Math.round(network.tokenTransferFeeInUsd)} $`);
    } else if (network.tokenTransferFeeInUsd) {
        feeArray.push("< 1 $");
    }

    const withdrawFee = feeArray.length !== 0 ? feeArray.join(" + ") : "\u2013";

    return {
        id: network.id,
        symbol: network.symbol,
        depositEnabled: true,
        withdrawEnabled: true,
        fixedWithdrawFee: network.tokenTransferFeeInUsd,
        confirm: "No",
        withdrawFee: withdrawFee,
        status: "Normal"
    }
}

export const convertExchangeAssets = (exchangeAssets: ExchangeAsset[]): ArbitrageItem[] => (
    exchangeAssets.map(exchangeAsset => ({
        exchange: exchangeAsset.exchange,
        markets: convertSpotMarkets(exchangeAsset.markets),
        chains: convertExchangeNetworks(exchangeAsset.networks),
        warning: exchangeAsset.warning
    }))
)

export const convertTokenContracts = (tokenContracts: TokenContract[]): ArbitrageItem[] => (
    tokenContracts.filter(token => token.aggregator && token.dexScreenerUrl).map(contract => ({
        exchange: {
            name: "DEX",
            url: contract.dexScreenerUrl!
        },
        markets: [{
            key: `${contract.network.id}-${contract.address}`,
            symbol: `${contract.aggregator!.market}`,
            buyUrl: contract.aggregator!.buyUrl,
            sellUrl: contract.aggregator!.sellUrl
        }],
        chains: [convertNetwork(contract.network)]
    }))
)